<template>
  <div class="content " style="margin-top: -50px">
    <div class="header text-center " style=";z-index: 1;">
      <br>

      <h1
        class="tulum-party-title"
        style="color: #ffffff !important;font-size: 1.8rem;margin-top: 8px;margin-bottom: 0; letter-spacing: 2px;

">
        TULUM PARTY</h1>
      <h2 class="tulum-party-title sub-header mb-0" style="font-size: 1.1rem;z-index: 10;">Parties, Nightlife & Techno
        Music
      </h2>
      <br>

      <h4 style="font-size: 5rem"> 📡 </h4>
      <h3 class="tulum-party-subtitle " style="text-transform: uppercase;font-size: 1.2rem;letter-spacing: 0.3rem">PARTY
        COMMUNITY
      </h3>
      <div style="max-width: 300px" class="centered">
        <p>When the pandemic times just started, we put together an underground channel to <strong>keep the Tulum's
          party scene together</strong>.</p>
        <p>As today the Community is still alive providing light for those in the dark</p>
      </div>



    </div>
    <div class="col-md-12">
      <card class="card text-center col-sm-12 col-md-8 offset-md-2">
        <template slot="default">

          <a href="https://t.me/tulumparty"
             class="btn btn-sm btn-primary"
             rel="nofollow noopener noreferrer"
             target="_blank"
             v-on:click="onJoinClick">
            <img
              v-lazy="heroImage"
              width="300px"
              height="250px"
              style="border-radius: 2px;"
              alt="Tulum Party"
            >
          </a>
          <br>
          <strong style="text-transform: uppercase;font-size: 0.7rem;letter-spacing: 0.3rem">Supporting the party scene during the hard & good times</strong>

          <br><br>
          <p style="color: aqua;font-weight: bold;">+3700 members !</p>
          <br>The channel has evolved as an open community to <strong>exchange any party information</strong>,
          experience or knowledge, handy to anyone looking for fun (party people).
          <br>
          <br>

          <div style="text-align: center !important;">
            <a href="https://t.me/tulumparty"
               class="btn btn-sm btn-primary"
               rel="nofollow noopener noreferrer"
               target="_blank"

               v-on:click="onJoinClick">
              Stay in Tune @ Telegram
            </a>
            <br>

          </div>
        </template>

        <div class="col-md-10 offset-md-1 text-center " v-if="communityPosts">

          <br>
          <br>

          <h4 style="font-size: 5rem">🗓 </h4>
          <h3 class="tulum-party-subtitle " style="text-transform: uppercase;font-size: 0.7rem;letter-spacing: 0.3rem">
            Community Guidelines</h3>


          <hr>
          <strong>No drugs & illegal activities</strong>

          <hr>
          <strong>No disrespect/racism/discrimination</strong>

          <hr>
          <strong>No spam</strong>
          <hr>

          <p>
            Our aim is to provide valuable information to tourists who are looking for parties and events.
          </p>

          <br>
          <p>
            When posting, please keep in mind that we value informative and engaging content. Make use of the telegram tools provided to create high-quality posts. While we do use the channel for monetization, we are happy to share opportunities with promoters and organizers as long as their posts are helpful for the community.



          </p>
          <br>
          <hr>
          <p>
            To increase your visibility, answer questions and engage with the community.
            <br>
            Please refrain from reposting events and avoid sending spam messages or engaging in any actions that could be detrimental to the community's goals. If you have any questions, don't hesitate to reach out to us.

          </p>

          <br>

          <p>

            <ImgCard
              image="https://imgix.cosmicjs.com/aa21d700-0f0d-11ee-8cf2-9720ae4c65d0-image.png"
              align="center"
              height="100"
              title="WE ARE LIVE !"
              width="300"
            text="<p>Hey there! Looking for exclusive content on Tulum’s party scene?<br>
            <b>Follow tulum.party on Instagram</b> for a Glimpse into the Hottest Events and find a social way to connect with us.</p> "
            action-label="tulum.party"
              action="https://instagram.com/tulum.party"
            link="https://instagram.com/tulum.party"
            >
            </ImgCard>
          </p>
        </div>
      </card>

    </div>
  </div>
</template>
<script>

import {TimeLine, TimeLineItem} from 'src/components';
import {mapState} from "vuex";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import ImgCard from "@/components/Cards/ImgCard";

export default {
  name: 'Home',
  created: function () {
    setTimeout(() => this.showPalms = true, 5000)
  },

  metaInfo() {
    return {
      title: 'Tulum Party - Community',
      meta: [
        {
          name: 'description',
          content: "TULUM.PARTY - Party Community. Live Updates & Information.",
        },
        {
          name: "keywords",
          content: "Tulum, Parties, Events, Party, Community, Forum, Playa del Carmen"
        },
        {property: 'og:title', content: `Tulum Party - Community`},
        {property: 'og:type', content: "website"},
        {
          property: 'og:description',
          content: `TULUM.PARTY - Community`
        },
        {
          property: 'og:image',
          content: "https://imgix.cosmicjs.com/df7ff070-76a4-11eb-96b6-e94a2ed95964-zamna.png"
        }
      ]
    }
  },
  data: () => {
    return {
      modal: {},
      start: 10,
      showPalms: false,
      bgPalm: "https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=5cddbe,black&duotone-alpha=600txt-fit=max&fit=crop",
      invitationId: null,
      invitationSubmitted: false,
      instagramImage: 'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      heroImage: 'https://imgix.cosmicjs.com/38878900-6c0b-11ed-8951-b39aeeb44ac4-image.png?w=300&h=250&fit=clamp',
    }
  },
  head: {
    script: function () {
      return this.buildMetadata();
    },
  },
  components: {
    ImgCard,
    TimeLine,
    TimeLineItem,
    Collapse,
    CollapseItem
  },
  methods: {
    onJoinInstagramClick() {
      window.ga('send', 'event', 'instagram', 'JOIN', 'Main', 5)
      this.$rollbar.info("Instagram join")
    },
    resizeIframe(obj) {
      obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    },
    onJoinClick() {
      window.ga('send', 'event', 'sms_list', 'JOIN', 'Main', 5)
      this.$rollbar.info(`JOIN Community`)
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    }
  },
  computed: mapState({
    communityPosts: state => state.communityPosts,
    status: state => state.status
  })
}
</script>
<style lang="scss" scoped>
.modal-content {
  color: #00f2c3;
  background-color: #000000 !important;
}

address {
  color: #00f2c3;
}
</style>
